import React, { useState } from 'react';
import Link from 'next/link';

import styles from './header.module.scss';
import useLanguage from '../_hooks/useLanguage';
import useNotification from '../_hooks/useNotification';
import NotificationList from '../notification/notification-list';
import NoResult from '../list-no-result/no-result';
import Loader from '../loader/loader';

const NotificationPopup = React.memo(() => {
	const { t } = useLanguage();

	// const { notificationsUnreadList: list, setAllUnreadAsRead, notificationIsRead: read, notificationIsVisible: visible, toggleNotifications } = useNotification('notification-popup', false);
	const { notificationsUnreadList: list, setAllUnreadAsRead, notificationIsRead: read, notificationIsVisible: visible, toggleNotifications } = {};

	const onNotificationToggle = () => {
		toggleNotifications(!visible);

		if (read === false) {
			setAllUnreadAsRead(false);
		}
	};

	return (
		<div className={styles.notificationOption}>
			<button type="button" onClick={() => onNotificationToggle()}><i className="icon bell" /></button>
			{read === false && list && list.length > 0 && <i className={styles.isNotification} />}
			{visible && (
				<>
					<button type="button" className={styles.overlayClose} onClick={() => toggleNotifications()} />
					<div className={`${styles.notifications}`}>
						<div className={styles.header}>
							<button type="button" onClick={() => setAllUnreadAsRead()}>{list !== null && list.length > 0 ? t('btnClean') : ''}</button>
							<h4>{t('labelAccountNotifications')}</h4>
							<Link href="/profil/obvestila-nastavitve"><a><i className="icon coq" /></a></Link>
						</div>

						{list === null && <Loader small />}
						{list !== null && list.length > 0 && <NotificationList list={list} />}
						{list !== null && list.length === 0 && <NoResult>Ni novih obvestil.</NoResult>}

						<Link href="/profil/obvestila"><a className={`btn plain primary ${styles.showAll}`}>{t('btnShowAll')}</a></Link>
					</div>
				</>
			)}
		</div>
	);
});

export default NotificationPopup;
