import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './notification.module.scss';
import useLanguage from '../_hooks/useLanguage';
import useNotification from '../_hooks/useNotification';

const NotificationList = ({ list, autoSetAsRead }) => {
	const { t } = useLanguage();

	const { setAsRead } = useNotification('component-notification-list', false);
	const [open, setOpen] = useState({});

	useEffect(() => {
		if (autoSetAsRead && list.length > 0) {
			const collector = [];
			list.map((row) => {
				if (row.isread === 0) {
					collector.push(row.log_notification_id);
				}

				return true;
			});

			if (collector.length > 0) {
				setAsRead({ log_notification_id: collector });
			}
		}
	}, [list]);

	if (list.length === 0) return null;

	return (
		<div className={styles.list}>
			{list.map((row, index) => {
				return (
					<div key={row.log_notification_id} className={`${styles.listItem} ${row.isread === 0 ? styles.unread : ''}`}>
						<div className={styles.header}>
							<div className={styles.icon}><i className="icon bell white" /></div>
							<p>{row.subject} {row.bodytext.length > 0 && <button type="button" onClick={() => setOpen({ ...open, [index]: !open[index] })}>{t(open[index] ? 'btnShowLess' : 'btnShowMore')}</button>}</p>
							<time>{row.created_dtm}</time>
						</div>
						{open[index] && row.bodytext.length > 0 && <div className={styles.body} dangerouslySetInnerHTML={{ __html: row.bodytext }} />}
					</div>
				);
			})}
		</div>
	);
};

NotificationList.defaultProps = {
	list: [],
	autoSetAsRead: false
};

NotificationList.propTypes = {
	list: PropTypes.arrayOf(PropTypes.shape({})),
	autoSetAsRead: PropTypes.bool
};

export default NotificationList;
