import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import styles from './navigation.module.scss';
import { useStore as appStore } from '../../contexts/app';
import useMobile from '../_hooks/useMobile';

const ItemBody = ({ icon, text, element }) => {
	return <>{icon ? <i className={`icon ${icon}`} /> : ''}{text}{element}</>;
};

ItemBody.defaultProps = {
	text: 'n/a',
	icon: null,
	element: null
};

ItemBody.propTypes = {
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node
	]),
	icon: PropTypes.string,
	element: PropTypes.node
};

const Item = ({ type, text, href, icon, classes, submenu, element, onClick, Component, visible, groupTitle }) => {
	if (visible === false) return null;

	const itemBody = <ItemBody text={text} icon={icon} element={element} />;

	// Mobile: close open "overlay" menu if item is clicked: why? if ex. /cenik was opened and user clicked "cenik" overlay was not closed (same route)
	const { isMobile } = useMobile();
	const { data: appData, dispatch: appDispatch } = appStore();

	const onBeforeRedirect = () => {
		if (isMobile && appData.headerMenuOpen) {
			appDispatch({ type: 'HEADER_MENU_OPEN', payload: false });
		}
	};

	const [isSubmenuToggle, setSubmenuToggle] = useState(false);

	switch (type) {
	default:
	case 'link':
		return <Link href={href}><a onClick={() => onBeforeRedirect()} className={classes || null}>{itemBody}</a></Link>;

	case 'button':
		return <button type="button" onClick={onClick} className={classes || null}>{itemBody}</button>;

	case 'submenu':
		return (
			<div className={`${styles.dropdown} ${classes || null} ${isSubmenuToggle ? styles.open : ''}`}>
				<button type="button" onClick={() => setSubmenuToggle(!isSubmenuToggle)}>{itemBody} <i className={`icon dropdown-arrow-down`} /></button>
				{isSubmenuToggle && <div className={styles.bgCloseTrigger} onClick={() => setSubmenuToggle(false)} />}
				{submenu && <Navigation list={submenu} />}
				{Component && <Component />}
			</div>
		);

	case 'component':
		return <Component />;

	case 'separator':
		return (
			<>
				<div className={styles.separator} />
				{groupTitle && <span className={styles.groupTitle}>{groupTitle}</span>}			
			</>
		);

	case 'text':
		return <div className={`${styles.plainText} ${classes || null}`}>{text}</div>;
	}
};

Item.defaultProps = {
	visible: true,
	type: 'link',
	classes: null,
	text: 'n/a',
	href: null,
	icon: null,
	submenu: null,
	element: null,
	Component: null,
	onClick: null,
	groupTitle: null
};

Item.propTypes = {
	visible: PropTypes.bool,
	type: PropTypes.string,
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node
	]),
	href: PropTypes.string,
	classes: PropTypes.string,
	icon: PropTypes.string,
	submenu: PropTypes.arrayOf(PropTypes.shape({})),
	element: PropTypes.node,
	Component: PropTypes.func,
	onClick: PropTypes.func,
	groupTitle: PropTypes.string
};

const Navigation = ({ list, isNavActive, title, onClose }) => {
	return (
		<nav className={`${isNavActive ? 'collapse' : ''} navbar-collapse ${styles.default}`}>
			{onClose && <button type="button" className={styles.close} onClick={() => onClose()}><i className="d-block d-lg-none icon times" /></button>}
			{title && <h2>{title}</h2>}
			{list.map(row => row && <Item key={row.text || Math.random()} {...row} />)}
		</nav>
	);
};

Navigation.defaultProps = {
	isNavActive: false,
	title: null,
	onClose: null
};

Navigation.propTypes = {
	list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	isNavActive: PropTypes.bool,
	title: PropTypes.string,
	onClose: PropTypes.func
};

export default Navigation;
