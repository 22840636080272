import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Navigation from '../navigation';
import Tag from '../../tag/tag';
import useLanguage from '../../_hooks/useLanguage';
import useAuth from '../../_hooks/useAuth';
import useApp from '../../_hooks/useApp';
import useProfile from '../../_hooks/useProfile';
import useUser from '../../_hooks/useUser';

const SubmenuProfile = ({ title, onClose }) => {
	const { logout, logoutIfTokenInvalid } = useAuth();
	const { t } = useLanguage();
	const { getSubmenutCnt, result } = useProfile();
	const { appDispatch } = useApp();
	const { userData, isMember, expireDtmFormated } = useUser();

	// Check, if administrator
	const [isAdministrator, setIsAdministrator] = useState(false);

	useEffect(() => setIsAdministrator(userData.isadministrator === true), [userData.isadministrator]);

	// Refresh counters

	const [cnt, setCnt] = useState({});

	// Get counters
	useEffect(() => getSubmenutCnt(), []);
	useEffect(() => {
		if (result) setCnt(result)
	}, [result])

	return (
		<Navigation
			title={title}
			onClose={onClose}
			list={[
				{ href: '/profil/shranjeno', text: t('navListSavedItems'), type: 'link', element: <Tag classes="rounded dark">{cnt.items_saved_cnt || 0}</Tag> },
				{ href: '/profil/shranjeno/filtri', text: t('navListSavedFilters'), type: 'link', element: <Tag classes="rounded dark">{cnt.filters_cnt || 0}</Tag> },
				{ href: '/profil/obvestila', text: t('navNotifications'), type: 'link' },
				{ href: '/profil/drazbe', text: t('navUserAuctions'), type: 'link' },
				{ href: '/profil', text: t('navUserAccount'), type: 'link' },
				{ type: 'separator', groupTitle: t('navAdminSectionTitle'), visible: isAdministrator },
				{ href: '/profil/administrator/drazbe', text: t('navAdminAuctions'), type: 'link', visible: isAdministrator },
				{ href: '/profil/administrator/prijave', text: t('navAdminApplications'), type: 'link', visible: isAdministrator },
				{ type: 'separator' },
				/*
				{ href: '/profil/moji-oglasi', text: t('navListInsertedItems'), icon: 'sign regular md', type: 'link', element: <Tag classes="rounded dark">{cnt.items_owned_cnt || 0}</Tag> },
				{ href: '/profil/vpis-oglasa', text: t('navAddAuction'), type: 'link', icon: 'plus-v2' },
				{ type: 'separator' },
				*/
				{ href: '/cenik', text: t('navPricelist'), type: 'link' },
				{ href: null, text: t('navHelp'), type: 'button', onClick: () => appDispatch({ type: 'SUPPORT_CHAT_TOGGLE'}) },
				{ type: 'separator' },
				{ href: '/odjava', text: t('navLogout'), type: 'button', onClick: () => logout(), classes: 'color-danger' },
				isMember ? { type: 'text', text: `${t('labelExpirePro')}: ${expireDtmFormated()}`, classes: 'smaller' } : null
			]}
		/>
	);
};

SubmenuProfile.defaultProps = {
	title: null,
	onClose: null
};

SubmenuProfile.propTypes = {
	title: PropTypes.string,
	onClose: PropTypes.func
};

export default SubmenuProfile;
